<template>
  <b-modal
    id="receiveForm"
    title="Получен"
    centered
    no-close-on-backdrop
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <validation-observer ref="validation">
      <b-form-group label="Наименование">
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input v-model="receive.title" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Статус">
        <b-form-checkbox
          v-model="receive.status"
          name="check-button"
          switch
          :checked="true"
          inline
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BFormCheckbox,
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      receive: {
        title: null,
        status: true,
      },
    }
  },
  methods: {
    ...mapActions('reference', ['ADD_RECEIVED_FROM', 'UPDATE_RECEIVED_FROM']),

    onSubmit() {
      this.$refs.validation.validate().then(success => {
        if (success) {
          const updateFile = this.modalValue?.id
            ? this.UPDATE_RECEIVED_FROM({
              id: this.modalValue.id,
              ...this.receive,
            })
            : this.ADD_RECEIVED_FROM(this.receive)

          updateFile.then(() => {
            this.$bvModal.hide('receiveForm')
            this.$emit('refresh')
            this.$_okToast()
          }).catch(e => this.$_errorToast(e))
        }
      })
    },

    openShowModal() {
      if (this.modalValue.id) {
        this.receive = JSON.parse(JSON.stringify(this.modalValue))
      } else {
        this.receive = {
          title: null,
          status: true,
        }
      }
    },
  },
}
</script>
