<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-md-2">
        <b-form-input
          v-model="filter.search"
          placeholder="Поиск..."
          type="search"
        />
      </b-col>

      <b-col class="col-md-2 ml-auto text-right">
        <b-button
          v-b-modal.receiveForm
          variant="primary"
          @click="modalValue = {}"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </b-button>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-table
        :fields="fields"
        :items="receiveForm.results"
        show-empty
        responsive
      >
        <template #empty>
          <empty />
        </template>

        <template #cell(status)="props">
          <b-form-checkbox
            v-model="props.item.status"
            name="check-button"
            switch
            disabled
            inline
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>

        <template #cell(actions)="props">
          <div class="d-flex justify-content-center">
            <b-button
              variant="warning"
              size="sm"
              @click="handleEdit(props.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              variant="danger"
              @click="handleDelete(props.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="filter.page_size"
            :options="[5, 10, 15]"
            class="mx-1"
            @change="refresh()"
          />
          <span class="ml-1 text-nowrap"> строк( {{ receiveForm.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="filter.page"
            :total-rows="receiveForm.count"
            :per-page="filter.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-overlay>

    <addEditModal
      :modal-value="modalValue"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BButton, BCol, BFormCheckbox, BFormInput, BFormSelect, BOverlay, BPagination, BRow, BTable,
} from 'bootstrap-vue'
import empty from '@components/empty.vue'
import { mapActions, mapState } from 'vuex'
import addEditModal from './addEditModal.vue'

export default {
  components: {
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BButton,
    BOverlay,
    BFormInput,
    BRow,
    BCol,
    empty,
    addEditModal,
  },
  data() {
    return {
      loading: false,
      filter: {
        search: null,
        page: 1,
        page_size: 10,
      },
      modalValue: {},
      fields: [
        {
          label: '№',
          key: 'id',
        },
        {
          label: 'Наименование',
          key: 'title',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Статус',
          key: 'status',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'ДЕЙСТВИЯ',
          key: 'actions',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
      ],
    }
  },
  computed: {
    ...mapState('reference', ['receiveForm']),
  },
  watch: {
    'filter.search': {
      handler() {
        clearTimeout(this.delay)
        this.delay = setTimeout(async () => {
          await this.refresh()
        }, 500)
      },
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('reference', ['FETCH_RECEIVED_FROM', 'DELETE_RECEIVED_FROM']),

    async refresh() {
      try {
        this.loading = true
        await this.FETCH_RECEIVED_FROM(this.filter)
      } catch (e) {
        await this.$_errorToast(e)
        this.loading = false
      }
      this.loading = false
    },

    changePage(page) {
      this.filter.page = page

      this.refresh()
    },

    handleDelete(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.filter.page = 1
            this.DELETE_RECEIVED_FROM(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              }).catch(e => this.$_errorToast(e))
          }
        })
    },

    handleEdit(val) {
      this.modalValue = val
      this.$nextTick(() => {
        this.$bvModal.show('receiveForm')
      })
    },
  },

}
</script>
