<template>
  <b-modal
    id="modal-work"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    ok-title="Сохранить"
    ok-variant="primary"
    cancel-title="Отменить"
    centered
    :title="docType.id ? 'Редактировать вид работ' : 'Добавите вид работ'"
    @show="showModal"
    @ok.prevent="onSubmitDoc"
  >
    <validation-observer ref="docValidation">
      <b-form-group
        label="Наименование"
      >
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input
            v-model="doc.name"
            @keydown.enter.exact="onSubmitDoc"
          />
          <small class="text-danger">
            {{ errors[0] }}
          </small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    docType: {
      type: Object,
      default: () => {
      },
      required: false,
    },
  },
  data() {
    return {
      required,
      doc: {
        name: '',
      },
    }
  },
  methods: {
    ...mapActions('reference', ['EDIT_WORK_TYPE', 'ADD_WORK_TYPE']),

    onSubmitDoc(bvModalEvt) {
      bvModalEvt.preventDefault()

      const val = this.$refs.docValidation.validate()
      if (!val) return

      if (this.docType.id) {
        this.EDIT_WORK_TYPE({
          id: this.docType.id,
          ...this.doc,
        })
          .then(() => {
            this.$emit('save')
            this.$bvModal.hide('modal-work')
          })
      } else if (this.doc.name) {
        this.ADD_WORK_TYPE(this.doc)
          .then(() => {
            this.$emit('save')
            this.$nextTick(() => {
              this.$bvModal.hide('modal-work')
            })
          })
          .catch(err => {
            this.$_errorToast(err)
          })
      }
    },

    showModal() {
      this.doc = {
        name: '',
      }

      if (!this.docType) {
        return
      }

      if (typeof this.docType === 'object' && this.docType !== null) {
        this.doc.name = this.docType.name
      }
    },
  },
  directives: {
    'b-modal': VBModal,
  },
}
</script>

<style scoped>

</style>
