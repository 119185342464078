<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-md-2">
        <b-form-input
          v-model="filter.search"
          placeholder="Поиск..."
          type="search"
          @input="searchConstructors"
        />
      </b-col>

      <b-col class="col-md-2 ml-auto text-right">
        <b-button
          v-b-modal.modal-doc-type
          variant="primary"
          @click="docType = {}"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </b-button>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-table
        :fields="fields"
        :items="document_types.results"
        show-empty
        responsive
      >
        <template #empty>
          <empty />
        </template>

        <template #cell(name)="{item, value}">
          <span v-if="!item.editing">
            {{ value }}
          </span>
          <b-form-input
            v-else
            v-model="item.name"
            class="w-50"
            autofocus
          />
        </template>

        <template #cell(is_ssp)="props">
          <b-form-checkbox
            v-model="props.item.is_ssp"
            name="check-button"
            switch
            :disabled="!props.item.editing"
            inline
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>

        <template #cell(actions)="props">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="!props.item.editing"
              variant="warning"
              size="sm"
              @click="doEditAction(props.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              v-if="props.item.editing"
              variant="success"
              size="sm"
              @click="doSaveAction(props.item)"
            >
              <!--editClaim(props)--old edit mt-- -->
              <feather-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-if="props.item.editing"
              class="ml-1"
              variant="danger"
              size="sm"
              @click="doCancelAction(props.item)"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <!--          <b-button-->
            <!--            size="sm"-->
            <!--            variant="warning"-->
            <!--            @click="editItem(props)"-->
            <!--          >-->
            <!--            <feather-icon-->
            <!--              icon="EditIcon"-->
            <!--              size="15"-->
            <!--            />-->
            <!--          </b-button>-->

            <b-button
              v-if="!props.item.editing"
              size="sm"
              class="ml-1"
              variant="danger"
              @click="handleDelete(props)"
            >
              <feather-icon
                icon="TrashIcon"
                size="15"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <Pagination
        class="mx-1 mt-2"
        :users_count="document_types.count"
        @changePaginationValue="changePaginationValue"
      />
    </b-overlay>

    <Modal
      :doc-type="docType"
      @save="FETCH_DOCUMENT_TYPES"
    />

    <!--    <button @click="saveToCookie">-->
    <!--      Save-->
    <!--    </button>-->
    <!--    <button @click="retrieveFromCookie">-->
    <!--      Retrieve-->
    <!--    </button>-->
    <!--    <pre>{{ cookie }}</pre>-->

    <!--    <input-->
    <!--      v-for="(digit, index) in digits"-->
    <!--      :key="index"-->
    <!--      v-model="digits[index]"-->
    <!--      type="text"-->
    <!--      maxlength="1"-->
    <!--      class="verification-digit"-->
    <!--      @input="focusNext(index, $event)"-->
    <!--      @keydown="handleKeyDown(index, $event)"-->
    <!--    >-->
    <!--    {{ digits }}-->
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BFormInput,
  BCol,
  BRow,
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import Pagination from '@/components/pagination.vue'
import empty from '@/components/empty.vue'
// import { cookies } from '@/@core/cookies'
import Modal from './modal.vue'

export default {
  name: 'Index',
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    Modal,
    Pagination,
    BOverlay,
    BFormCheckbox,
    BFormInput,
    empty,
  },
  mixins: [areYouSure],
  data() {
    return {
      // digits: Array(4).fill(''),
      is_edit: false,
      filter: {
        search: null,
        page: 1,
        page_size: 10,
      },
      docType: {},
      fields: [
        {
          label: 'Наименование',
          key: 'name',
          editable: true,
        },
        {
          label: 'Ссп',
          key: 'is_ssp',
          editable: true,
        },
        {
          label: 'действия',
          key: 'actions',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
      ],
      loading: false,
      selected: [],
      // cookie: null,
    }
  },
  computed: {
    ...mapState('reference', ['document_types']),
  },
  created() {
    document.title = 'Вид действия'

    // this.cookie = cookies.get('refresh')
  },
  async mounted() {
    try {
      this.loading = true
      await this.FETCH_DOCUMENT_TYPES(this.filter)
    } catch (error) {
      await this.$_errorToast()
    }
    this.loading = false
  },
  methods: {
    ...mapActions('reference', ['FETCH_DOCUMENT_TYPES', 'DELETE_DOCUMENT_TYPE', 'EDIT_DOCUMENT_TYPE']),
    //
    // focusNext(index, event) {
    //   const input = event.target
    //   if (input.value !== '') {
    //     if (index < this.digits.length - 1) {
    //       input.nextElementSibling.focus()
    //     }
    //   }
    // },
    // handleKeyDown(index, event) {
    //   const input = event.target
    //   if (event.key === 'Backspace' && input.value === '') {
    //     if (index > 0) {
    //       input.previousElementSibling.focus()
    //     }
    //   }
    // },

    // saveToCookie() {
    //   const local = localStorage.getItem('refresh')
    //   cookies.set('refresh', local, { path: '/' })
    //   cookies.get('refresh')
    //
    //   this.cookie = cookies.get('refresh')
    // },
    //
    // retrieveFromCookie() {
    //   cookies.remove('refresh')
    //   this.cookie = null
    // },

    doEditAction(item) {
      this.$set(item, 'editing', true)
    },

    doSaveAction(item) {
      this.$set(item, 'editing', false)

      const {
        id,
        is_ssp,
        name,
      } = item

      this.EDIT_DOCUMENT_TYPE({
        id,
        is_ssp,
        name,
      })
        .then(() => {
          this.FETCH_DOCUMENT_TYPES(this.filter)
          this.$_okToast()
        })
    },

    doCancelAction(item) {
      this.FETCH_DOCUMENT_TYPES(this.filter)
      this.$set(item, 'editing', false)
    },

    changePaginationValue({
      page,
      page_size,
    }) {
      this.filter.page = page
      // eslint-disable-next-line camelcase
      this.filter.page_size = page_size

      this.FETCH_DOCUMENT_TYPES(this.filter)
    },

    searchConstructors() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.FETCH_DOCUMENT_TYPES(this.filter)
      }, 600)
    },

    // editItem(props) {
    //   this.docType = props.item
    //   this.$nextTick(() => {
    //     this.$bvModal.show('modal-doc-type')
    //   })
    // },

    handleDelete(props) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DOCUMENT_TYPE(props.item.id)
              .then(() => {
                this.FETCH_DOCUMENT_TYPES(this.filter)
                this.$_okToast()
              })
              .catch(() => {
                this.$_errorToast('Ошибка')
              })
          }
        })
    },
  },
}
</script>

<style>
.verification-digit {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  text-align: center;
}

.tableHeadClass {
  text-align: center;
}
</style>
