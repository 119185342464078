<template>
  <b-card class="card__tabs">
    <b-tabs
      vertical
      pills
      nav-wrapper-class="nav-vertical"
    >
      <b-tab
        title="Вид действия"
        lazy
      >
        <doc />
      </b-tab>

      <b-tab
        title="Вид работ"
        lazy
      >
        <forms />
      </b-tab>

      <b-tab
        title="Взыскатели"
        lazy
      >
        <claimnats />
      </b-tab>

      <!--      <b-tab-->
      <!--        title="Вид"-->
      <!--        lazy-->
      <!--      >-->
      <!--        <docForm />-->
      <!--      </b-tab>-->

      <b-tab
        title="Получен"
        lazy
      >
        <receivedForm />
      </b-tab>

      <b-tab
        title="Состояние"
        lazy
      >
        <docStatus />
      </b-tab>

      <b-tab
        title="Документа"
        lazy
      >
        <titlePage />
      </b-tab>
    </b-tabs>

    <!--    <div-->
    <!--      class="p-1"-->
    <!--      style="max-width: 400px; margin: 50px auto; background: #234"-->
    <!--    >-->

    <!--      &lt;!&ndash; Calculator Result &ndash;&gt;-->
    <!--      <div class="w-full rounded m-1 p-3 text-right lead font-weight-bold text-white bg-vue-dark">-->
    <!--        {{ calculatorValue || 0 }}-->
    <!--      </div>-->

    <!--      &lt;!&ndash; Calculator buttons &ndash;&gt;-->
    <!--      <div class="row no-gutters">-->
    <!--        <div-->
    <!--          v-for="n in calculatorElements"-->
    <!--          :key="n"-->
    <!--          class="col-3"-->
    <!--        >-->
    <!--          <div-->
    <!--            class="lead text-white text-center m-1 py-3 bg-vue-dark rounded hover-class"-->
    <!--            :class="{'bg-vue-green': ['C','*','/','-','+','%','='].includes(n)}"-->
    <!--            @click="action(n)"-->
    <!--          >-->
    <!--            {{ n }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import doc from '@/modules/reference/components/action/index.vue'
import forms from '@/modules/reference/components/workType/index.vue'
import claimnats from '@/modules/reference/components/claimants/index.vue'
import receivedForm from '@/modules/reference/components/fileReference/receivedForm/index.vue'
import docForm from './fileReference/docForm/index.vue'
import docStatus from './fileReference/docStatus/index.vue'
import titlePage from './title/index.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    doc,
    forms,
    claimnats,
    docForm,
    receivedForm,
    docStatus,
    titlePage,
  },
  // data() {
  //   return {
  //     calculatorValue: '',
  //     calculatorElements: ['C', '*', '/', '-', 7, 8, 9, '+', 4, 5, 6, '%', 1, 2, 3, '=', 0, '.'],
  //     operator: null,
  //     previousCalculatorValue: '',
  //   }
  // },

  methods: {
    // action(n) {
    //   /* Append value */
    //   // eslint-disable-next-line no-restricted-globals
    //   if (!isNaN(n) || n === '.') {
    //     this.calculatorValue += `${n}`
    //   }
    //   /* Clear value */
    //   if (n === 'C') {
    //     this.calculatorValue = ''
    //   }
    //   /* Percentage */
    //   if (n === '%') {
    //     this.calculatorValue = `${this.calculatorValue / 100}`
    //   }
    //   /* Operators */
    //   if (['/', '*', '-', '+'].includes(n)) {
    //     this.operator = n
    //     this.previousCalculatorValue = this.calculatorValue
    //     this.calculatorValue = ''
    //   }
    //   /* Calculate result using the eval function */
    //   if (n === '=') {
    //     // eslint-disable-next-line no-eval
    //     this.calculatorValue = eval(this.previousCalculatorValue + this.operator + this.calculatorValue)
    //     this.previousCalculatorValue = ''
    //     this.operator = null
    //   }
    // },
  },
}
</script>

<style>
.card__tabs .nav-pills .nav-link, .nav-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: start !important;
}

/*.card__tabs .nav-vertical {*/
/*  width: 240px;*/
/*}*/

/*.bg-vue-dark {*/
/*  background: #31475e;*/
/*}*/
/*.hover-class:hover {*/
/*  cursor: pointer;*/
/*  background: #3D5875;*/
/*}*/
/*.bg-vue-green {*/
/*  background: #3fb984;*/
/*}*/
</style>
