<template>
  <b-modal
    id="modal-doc-type"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    ok-title="Сохранить"
    ok-variant="primary"
    cancel-title="Отменить"
    centered
    :title="typeOfModal"
    @show="showModal"
    @ok.prevent="onSubmitDoc"
  >
    <validation-observer ref="docValidation">
      <b-form-group
        label="Наименование"
      >
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input
            v-model="doc.name"
            @keydown.enter.exact="onSubmitDoc"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Ссп">
        <b-form-checkbox
          v-model="doc.is_ssp"
          name="check-button"
          switch
          inline
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    docType: {
      type: Object,
      default: () => {
      },
      required: false,
    },
  },
  data() {
    return {
      required,
      doc: {
        name: '',
        is_ssp: false,
      },
    }
  },
  computed: {
    typeOfModal() {
      return this.docType.id ? 'Редактировать вид действия' : 'Добавите вид действия'
    },
  },
  methods: {
    ...mapActions('reference', ['EDIT_DOCUMENT_TYPE', 'ADD_DOCUMENT_TYPE']),

    onSubmitDoc(bvModalEvt) {
      bvModalEvt.preventDefault()

      const req = this.$refs.docValidation.validate()
      if (!req) return

      if (this.docType.id) {
        this.EDIT_DOCUMENT_TYPE({
          id: this.docType.id,
          ...this.doc,
        })
          .then(() => {
            this.$_okToast()
            this.$emit('save')
            this.$bvModal.hide('modal-doc-type')
          })
      } else if (this.doc.name) {
        this.ADD_DOCUMENT_TYPE(this.doc)
          .then(() => {
            this.$emit('save')
            this.$nextTick(() => {
              this.$bvModal.hide('modal-doc-type')
            })
          })
          .catch(err => {
            this.$_errorToast(err)
          })
      }
    },

    showModal() {
      this.doc = JSON.parse(JSON.stringify(this.docType))
    },
  },
}
</script>
