<template>
  <b-modal
    id="docStatus"
    title="Состояние"
    centered
    no-close-on-backdrop
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <validation-observer ref="validation">
      <b-form-group label="Наименование">
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input v-model="docStatus.title" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!--      <b-form-group label="Статус">-->
      <!--        <b-form-checkbox-->
      <!--          v-model="docStatus.status"-->
      <!--          name="check-button"-->
      <!--          switch-->
      <!--          :checked="true"-->
      <!--          inline-->
      <!--        >-->
      <!--          <span class="switch-icon-left">-->
      <!--            <feather-icon icon="CheckIcon" />-->
      <!--          </span>-->
      <!--          <span class="switch-icon-right">-->
      <!--            <feather-icon icon="XIcon" />-->
      <!--          </span>-->
      <!--        </b-form-checkbox>-->
      <!--      </b-form-group>-->
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      docStatus: {
        title: null,
        // status: true,
      },
    }
  },
  methods: {
    ...mapActions('reference', ['ADD_DOC_STATUS', 'UPDATE_DOC_STATUS']),

    onSubmit() {
      this.$refs.validation.validate().then(success => {
        if (success) {
          const updateFile = this.modalValue?.id
            ? this.UPDATE_DOC_STATUS({
              id: this.modalValue.id,
              ...this.docStatus,
            })
            : this.ADD_DOC_STATUS(this.docStatus)

          updateFile.then(() => {
            this.$bvModal.hide('docStatus')
            this.$emit('refresh')
            this.$_okToast()
          }).catch(e => this.$_errorToast(e))
        }
      })
    },

    openShowModal() {
      if (this.modalValue.id) {
        this.docStatus = JSON.parse(JSON.stringify(this.modalValue))
      } else {
        this.docStatus = {
          title: null,
          status: true,
        }
      }
    },
  },
}
</script>
