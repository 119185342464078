<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-md-2">
        <b-form-input
          v-model="filter.search"
          placeholder="Поиск..."
          type="search"
          @input="searchConstructors"
        />
      </b-col>

      <b-col class="col-md-2 ml-auto text-right">
        <b-button
          v-b-modal.modal-claimants
          variant="primary"
          @click="claimantsItem = {}"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </b-button>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-table
        :fields="fields"
        :items="claimantsData.results"
        show-empty
        responsive
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(COMPANY_NAME)="{item, value}">
          <span v-if="!item.editing">
            {{ value }}
          </span>
          <b-form-input
            v-else
            v-model="item.COMPANY_NAME"
            autofocus
            class="m-auto text-center"
            @keydown.enter.exact="doSave(item)"
          />
        </template>

        <template #cell(actions)="props">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="!props.item.editing"
              variant="warning"
              size="sm"
              @click="doEdit(props.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              v-if="props.item.editing"
              variant="success"
              size="sm"
              @click="doSave(props.item)"
            >
              <!--editClaim(props)--old edit mt-- -->
              <feather-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-if="props.item.editing"
              class="ml-1"
              variant="danger"
              size="sm"
              @click="doCancel(props.item)"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <b-button
              v-if="!props.item.editing"
              size="sm"
              class="ml-1"
              variant="danger"
              @click="deleteClaimant(props.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>

      </b-table>

      <Pagination
        class="mx-1 mt-2"
        :users_count="claimantsData.count"
        @changePaginationValue="changePaginationValue"
      />
    </b-overlay>
    <claimants
      :claimants-item="claimantsItem"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BTable,
  BOverlay,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/pagination.vue'
import empty from '@/components/empty.vue'
import areYouSure from '@/mixins/areYouSure'
import claimants from './modal.vue'

export default {
  name: 'Index',
  components: {
    BTable,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BButton,
    Pagination,
    claimants,
    empty,
  },
  mixins: [areYouSure],
  data() {
    return {
      loading: false,
      claimantsItem: {},
      filter: {
        page: 1,
        page_size: 10,
        search: null,
      },
      fields: [
        {
          label: '№',
          key: 'COMPANY_ID',
        },
        {
          label: 'Наименование',
          key: 'COMPANY_NAME',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
          editable: true,
        },
        {
          label: 'ДЕЙСТВИЯ',
          key: 'actions',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
      ],
    }
  },
  computed: {
    ...mapState('reference', ['claimantsData']),
  },
  created() {
    document.title = 'Взыскатели'
  },
  async mounted() {
    try {
      this.loading = true
      await this.FETCH_CLAIMANT_TYPE(this.filter)
    } catch (error) {
      await this.$_errorToast()
    }
    this.loading = false
  },
  methods: {
    ...mapActions('reference', ['FETCH_CLAIMANT_TYPE', 'DELETE_CLAIMANT_TYPE', 'UPDATE_CLAIMANT_TYPE']),

    doSave(item) {
      this.$set(item, 'editing', false)

      const { COMPANY_ID, COMPANY_NAME } = item

      this.UPDATE_CLAIMANT_TYPE({
        COMPANY_ID,
        COMPANY_NAME,
      }).then(() => {
        this.refresh()
        this.$_okToast()
      })
    },

    doCancel(item) {
      this.$set(item, 'editing', false)
      this.refresh()
    },

    doEdit(item) {
      this.$set(item, 'editing', true)
    },

    changePaginationValue({
      page,
      page_size,
    }) {
      this.filter.page = page
      // eslint-disable-next-line camelcase
      this.filter.page_size = page_size

      this.refresh()
    },

    // editClaim(props) {
    //   this.claimantsItem = props.item
    //   this.$nextTick(() => {
    //     this.$bvModal.show('modal-claimants')
    //   })
    // },

    deleteClaimant(item) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_CLAIMANT_TYPE(item.COMPANY_ID)
              .then(() => {
                this.refresh()
              })
          }
        })
    },

    async refresh() {
      try {
        this.loading = true
        await this.FETCH_CLAIMANT_TYPE(this.filter)
      } catch (e) {
        await this.$_errorToast(e)
      }
      this.loading = false
    },

    searchConstructors() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refresh()
      }, 600)
    },
  },
}
</script>
