<template>
  <div>
<!--    <b-row>-->
<!--      <b-col md="6">-->
<!--        <b-form-input-->
<!--          v-model="search"-->
<!--          placeholder="Поиск"-->
<!--          class="mb-1"-->
<!--          @input="findLocation"-->
<!--        />-->

<!--        <div-->
<!--          v-for="(item, index) in features"-->
<!--          :key="index"-->
<!--          :class="indeex === index ? 'active rounded' : ''"-->
<!--        >-->
<!--          <div-->
<!--            v-if="search"-->
<!--            class="p-1 rounded border cursor-pointer mb-50"-->
<!--            @click="setLocation(item.geometry, index)"-->
<!--          >-->
<!--            <small>-->
<!--              {{ item.properties.name }} <span>({{ item.properties.GeocoderMetaData.text }})</span>-->
<!--            </small>-->
<!--          </div>-->
<!--        </div>-->
<!--      </b-col>-->

<!--      <b-col md="6">-->
<!--        <l-map-->
<!--          class="mt-1 rounded"-->
<!--          :zoom="zoom"-->
<!--          :center="center"-->
<!--          @click="mapClicked"-->
<!--        >-->
<!--          <l-tile-layer :url="url" />-->
<!--          <l-marker :lat-lng="markerLatLng">-->
<!--            <LIcon-->
<!--              :icon-size="[50, 50]"-->
<!--              :icon-url="require('@/assets/point8.svg')"-->
<!--            />-->
<!--          </l-marker>-->
<!--        </l-map>-->
<!--      </b-col>-->
<!--    </b-row>-->
    <index />
  </div>
</template>

<script>
// import {
//   LMap, LTileLayer, LMarker, LIcon,
// } from 'vue2-leaflet'
// import 'leaflet/dist/leaflet.css'
// import { BFormInput, BRow, BCol } from 'bootstrap-vue'
// import axios from 'axios'
import index from '../components/index.vue'

export default {
  name: 'Home',
  components: {
    index,
    // LMap,
    // LTileLayer,
    // LMarker,
    // LIcon,
    // BFormInput,
    // BRow,
    // BCol,
  },
  // data() {
  //   return {
  //     zoom: 18,
  //     center: [41.311158, 69.279737],
  //     url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  //     markerLatLng: [41.311158, 69.279737],
  //
  //     search: 'Tashkent',
  //     features: [],
  //     indeex: 0,
  //   }
  // },
  // mounted() {
  //   this.refresh()
  // },
  // methods: {
  //   mapClicked(event) {
  //     this.markerLatLng = event.latlng
  //   },
  //
  //   refresh() {
  //     axios.get(`https://search-maps.yandex.ru/v1/?text=${this.search}&type=geo&lang=en_US&apikey=76991167-004f-460a-8741-153b0762aa6d`)
  //       .then(res => {
  //         this.features = res.data?.features
  //       })
  //   },
  //
  //   setLocation(geometry, e) {
  //     this.indeex = e
  //
  //     if (geometry && geometry.coordinates && Array.isArray(geometry.coordinates)) {
  //       const [longitude, latitude] = geometry.coordinates
  //       const swappedCoordinates = [latitude, longitude]
  //       this.center = swappedCoordinates
  //       this.markerLatLng = swappedCoordinates
  //     } else {
  //       this.center = [41.311158, 69.279737]
  //       this.markerLatLng = [41.311158, 69.279737]
  //     }
  //   },
  //
  //   findLocation() {
  //     clearTimeout(this.delay)
  //     this.delay = setTimeout(async () => {
  //       this.refresh()
  //     }, 500)
  //   },
  // },
}
</script>

<style scoped lang="scss">
//.vue2leaflet-map {
//  &.leaflet-container {
//    height: 500px !important;
//  }
//}
//
//.leaflet-popup-content {
//  min-width: 400px !important;
//}
//
//.active {
//  background-color: #ccc;
//}
</style>
