<template>
  <b-modal
    id="titleId"
    title="Документа"
    centered
    no-close-on-backdrop
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <validation-observer ref="validation">
      <b-form-group label="Наименование">
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input
            v-model="value.name"
            @keydown.enter="onSubmit"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: {
        name: null,
      },
    }
  },
  methods: {
    ...mapActions('reference', ['CREATE_TITLE', 'UPDATE_TITLE']),

    onSubmit() {
      this.$refs.validation.validate().then(success => {
        if (success) {
          const updateFile = this.modalValue?.id
            ? this.UPDATE_TITLE({
              id: this.modalValue.id,
              ...this.value,
            })
            : this.CREATE_TITLE(this.value)

          updateFile.then(() => {
            this.$bvModal.hide('titleId')
            this.$emit('refresh')
            this.$_okToast()
          }).catch(e => this.$_errorToast(e))
        }
      })
    },

    openShowModal() {
      if (this.modalValue.id) {
        this.value = JSON.parse(JSON.stringify(this.modalValue))
      } else {
        this.value = {
          name: null,
        }
      }
    },
  },
}
</script>
