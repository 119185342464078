<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-md-2">
        <b-form-input
          v-model="filter.search"
          placeholder="Поиск..."
          type="search"
          @input="searchConstructors"
        />
      </b-col>

      <b-col class="col-md-2 ml-auto text-right">
        <b-button
          v-b-modal.modal-work
          variant="primary"
          @click="workType = {}"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </b-button>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-table
        :fields="fields"
        :items="forms.results"
        show-empty
        responsive
      >
        <template #empty>
          <empty />
        </template>

        <template #cell(name)="{item, value}">
          <span v-if="!item.editing">
            {{ value }}
          </span>
          <b-form-input
            v-else
            v-model="item.name"
            class="w-50"
            autofocus
          />
        </template>

        <template #cell(actions)="props">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="!props.item.editing"
              variant="warning"
              size="sm"
              @click="doEdit(props.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              v-if="props.item.editing"
              variant="success"
              size="sm"
              @click="doSave(props.item)"
            >
              <!--editClaim(props)--old edit mt-- -->
              <feather-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-if="props.item.editing"
              class="ml-1"
              variant="danger"
              size="sm"
              @click="doCancel(props.item)"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <!--          <b-button-->
            <!--            v-b-modal.modal-work-->
            <!--            size="sm"-->
            <!--            variant="warning"-->
            <!--            @click="workType = props.item"-->
            <!--          >-->
            <!--            <feather-icon-->
            <!--              icon="EditIcon"-->
            <!--              size="15"-->
            <!--            />-->
            <!--          </b-button>-->

            <b-button
              v-if="!props.item.editing"
              size="sm"
              class="ml-1"
              variant="danger"
              @click="handleDelete(props)"
            >
              <feather-icon
                icon="TrashIcon"
                size="15"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <Pagination
        class="mx-1 mt-2"
        :users_count="forms.count"
        @changePaginationValue="changePaginationValue"
      />
    </b-overlay>

    <Modal
      :doc-type="workType"
      @save="FETCH_WORK_TYPE"
    />
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCol,
  BRow,
  BOverlay,
  BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import Pagination from '@/components/pagination.vue'
import empty from '@/components/empty.vue'
import Modal from './modal.vue'

export default {
  name: 'Index',
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    Modal,
    Pagination,
    BOverlay,
    BFormInput,
    empty,
  },
  mixins: [areYouSure],
  data() {
    return {
      loading: false,
      filter: {
        search: null,
        page: 1,
        page_size: 10,
      },
      workType: {},
      fields: [
        {
          label: 'Наименование',
          key: 'name',
          editable: true,
        },
        {
          label: 'действия',
          key: 'actions',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
      ],
    }
  },
  computed: {
    ...mapState('reference', ['forms']),
  },
  created() {
    document.title = 'Вид работ'
  },
  async mounted() {
    try {
      this.loading = true
      await this.FETCH_WORK_TYPE(this.filter)
    } catch (error) {
      await this.$_errorToast()
    }
    this.loading = false
  },
  methods: {
    ...mapActions('reference', ['FETCH_WORK_TYPE', 'DELETE_WORK_TYPE', 'EDIT_WORK_TYPE']),

    doSave(item) {
      this.$set(item, 'editing', false)
      const { id, name } = item

      this.EDIT_WORK_TYPE({
        id,
        name,
      }).then(() => {
        this.FETCH_WORK_TYPE(this.filter)
        this.$_okToast()
      })
    },

    doCancel(item) {
      this.$set(item, 'editing', false)
      this.FETCH_WORK_TYPE(this.filter)
    },

    doEdit(item) {
      this.$set(item, 'editing', true)
    },

    changePaginationValue({ page, page_size }) {
      this.filter.page = page
      // eslint-disable-next-line camelcase
      this.filter.page_size = page_size

      this.FETCH_WORK_TYPE(this.filter)
    },

    searchConstructors() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.FETCH_WORK_TYPE(this.filter)
      }, 600)
    },

    handleDelete(props) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_WORK_TYPE(props.item.id)
              .then(() => {
                this.FETCH_WORK_TYPE()
                this.$_okToast()
              })
              .catch(() => {
                this.$_errorToast('Ошибка')
              })
          }
        })
    },
  },
}
</script>

<style>
.tableHeadClass {
  text-align: center;
}
</style>
