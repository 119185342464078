<template>
  <b-modal
    id="modal-claimants"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    ok-title="Сохранить"
    :ok-variant="claimantsItem.id ? 'warning' : 'primary'"
    cancel-title="Отменить"
    centered
    :title="claimantsItem.id ? 'Редактировать взыскателей' : 'Добавите взыскателей'"
    @show="showModal"
    @ok.prevent="onSubmitClaim"
  >
    <validation-observer ref="claimantsValidation">
      <b-form-group
        label="Наименование"
      >
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input
            v-model="claim.COMPANY_NAME"
            @keydown.enter.exact="onSubmitClaim"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'

export default {
  name: 'Modal',
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    claimantsItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      claim: {
        COMPANY_NAME: '',
      },
    }
  },
  methods: {
    ...mapActions('reference', ['CREATE_CLAIMANT_TYPE', 'UPDATE_CLAIMANT_TYPE']),

    onSubmitClaim() {
      if (this.claimantsItem.id) {
        this.UPDATE_CLAIMANT_TYPE({
          COMPANY_ID: this.claimantsItem.COMPANY_ID,
          ...this.claim,
        })
          .then(() => {
            this.$bvModal.hide('modal-claimants')
            this.$emit('refresh')
          })
      } else if (this.claim.COMPANY_NAME) {
        this.CREATE_CLAIMANT_TYPE(this.claim)
          .then(() => {
            this.$bvModal.hide('modal-claimants')
            this.$emit('refresh')
          })
      }
    },

    showModal() {
      this.claim = JSON.parse(JSON.stringify(this.claimantsItem))
    },
  },
}
</script>
